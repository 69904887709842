import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Education.css";
import "./PageStyling.css";
import "react-slideshow-image/dist/styles.css";
import { Slide } from "react-slideshow-image";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

/**
 * Page containing education information
 * @param props
 *          transcript: pdf file from Google Drive
 *          images: array of 3 image src
 *          captions: array of 3 captions complementing images array
 * @returns {JSX.Element}
 */
const Education = (props) => {
  const images = props.images;
  const captions = props.captions;
  const transcript = props.transcript;

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: "#59a2d9", paddingTop: "1vh" }}>
      {/* Outer container for color and width */}
      <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
        {/* Box determines container height and some bottom padding */}
        {/* Grid is used for remaining content, Slide acts funny when inside a Grid */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* School info */}
          <div className={"edutext"}>
            <h2>Virginia Tech</h2>
            <p>B.S. Computer Science</p>
            <p>Human Computer Interaction Minor</p>
            <p>University GPA: 3.74/4.0</p>
            <p>Major GPA: 3.76/4.0</p>
          </div>
          {/* Course work info */}
          <div className={"courseworktext"}>
            <h2>Relevant Coursework</h2>
            <p>Data structures and algorithms</p>
            <p>Computer systems</p>
            <p>Data Analysis and Visualization with Python</p>
            <p>GUI Programming with JS</p>
            <p>Human-Computer Interaction</p>
            <p>Software Engineering Capstone</p>
            <p>Multimedia Capstone</p>
            <p>HCI Capstone</p>
          </div>
          {/* Course work pdf */}
          <h2 className={"allcourseworktext"}>All Coursework:</h2>
          <iframe
            className={"courseworkPDF"}
            src={transcript}
            width="90%"
            height="1000"
            allow="autoplay"
          ></iframe>
        </Grid>
      </Box>
    </Container>
  );
};
export default Education;
