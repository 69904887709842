import "./App.css";
import NavBar from "./components/NavBar";
import Education from "./pages/Education";
import About from "./pages/About";
import Awards from "./pages/Awards";
import Skills from "./pages/Skills";
import Projects from "./pages/Projects";
import WorkExperience from "./pages/WorkExperience";
import Resume from "./pages/Resume";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Container from "@mui/material/Container";
import FooterBar from "./components/FooterBar";
import torg_interior from "./images/torg_interior.jpg";
import mcb from "./images/mcb.jpg";
import squires from "./images/squires.jpg";
import c from "./images/c.jpg";
import css from "./images/css.png";
import eclipse from "./images/eclipse.png";
import expressjs from "./images/expressjs.png";
import gitbash from "./images/gitbash.webp";
import github from "./images/github.jpg";
import html from "./images/html.png";
import idea from "./images/idea.png";
import java from "./images/java.jpg";
import js from "./images/js.png";
import jupyter from "./images/jupyter.png";
import linux from "./images/linux.png";
import mongodb from "./images/mongodb.png";
import python from "./images/python.jpg";
import react from "./images/react.svg";
import vscode from "./images/vscode.png";
import webstorm from "./images/webstorm.png";
import pyqt5 from "./images/pyqt5.webp";
import sqllite3 from "./images/sqllite3.png";
import d3 from "./images/d3.jfif";
import "@fontsource/roboto"; // Defaults to weight 400.
import business from "./images/business1.jpg";
import bootstrap from "./images/bootstrap.png";
import heroku from "./images/heroku.png";
import django from "./images/django.png";
import flask from "./images/flask.png";
import jplab from "./images/jplab.png";
import angular from "./images/angular.png";

const pages = [
  "About",
  "Resume",
  "Education",
  "Awards",
  "Skills",
  "Projects",
  "Work Experience",
];
const links = [
  "/",
  "/resume",
  "/education",
  "/awards",
  "/skills",
  "/projects",
  "/work experience",
];
const images = [torg_interior, mcb, squires];
const captions = [
  "Torgersen Hall Interior",
  "McBryde Hall",
  "Squires Student Center",
];
const pdf =
  "https://drive.google.com/file/d/1pGUnCwLFkMDjwXzW9Dsu0xrjv0rv33Gs/preview";
const resumepdf =
  "https://drive.google.com/file/d/1Ckx5sw1Iyu0ZIXreyNL9h4gxENPBOC2e/preview";
const languages = [
  { title: "java", img: java },
  { title: "C", img: c },
  { title: "Python", img: python },
  { title: "HTML", img: html },
  {
    title: "CSS",
    img: css,
  },
  { title: "D3.js", img: d3 },
  { title: "JS", img: js },
  { title: "ExpressJS", img: expressjs },
  { title: "ReactJS", img: react },
  { title: "Bootstrap", img: bootstrap },
  { title: "Django", img: django },
  { title: "MongoDB", img: mongodb },
  { title: "Flask", img: flask },
];
const softwares = [
  { title: "Eclipse", img: eclipse },
  { title: "GitBash", img: gitbash },
  {
    title: "GitHub",
    img: github,
  },
  { title: "Intellij Idea", img: idea },
  { title: "Jupyter Notebook", img: jupyter },
  { title: "JupyterLab", img: jplab },
  {
    title: "Linux",
    img: linux,
  },
  { title: "VSCode", img: vscode },
  { title: "Heroku", img: heroku },
  { title: "Webstorm", img: webstorm },
  { title: "Sqllite3", img: sqllite3 },
];
const futures = [
  { title: "PyQT5", img: pyqt5 },
  { title: "Angular", img: angular },
];
const transcript =
  "https://drive.google.com/file/d/1kzH9RF30wUgdmWvXgxfETEOLTsk5dhiK/preview";
const App = () => {
  return (
    <>
      <Container className="App" maxWidth={"xl"}>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route
              path={"/"}
              element={<NavBar pages={pages} links={links}></NavBar>}
            >
              <Route index element={<About image={business} />} />
              <Route
                path={"/education"}
                element={
                  <Education
                    images={images}
                    captions={captions}
                    transcript={transcript}
                  />
                }
              />
              <Route path={"/resume"} element={<Resume pdf={resumepdf} />} />
              <Route path={"/awards"} element={<Awards pdf={pdf} />} />
              <Route
                path={"/skills"}
                element={
                  <Skills
                    languages={languages}
                    softwares={softwares}
                    futures={futures}
                  />
                }
              />
              <Route path={"/projects"} element={<Projects />} />
              <Route path={"/work experience"} element={<WorkExperience />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Container>
      <FooterBar></FooterBar>
    </>
  );
};

export default App;
