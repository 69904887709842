import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Resume.css";
import "./PageStyling.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

/**
 * Award page
 * @param props
 *          pdf: Google Drive pdf link to embed
 * @returns {JSX.Element}
 */
const Resume = (props) => {
  const pdf = props.pdf;

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: "#59a2d9" }}>
      {/* Container colors the page and sets the width */}
      <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
        {/* Box of content determines the height */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"2%"}
        >
          {/* Page elements are laid out in a column */}
          <iframe
            className={"ResumePDF"}
            src={pdf}
            width="90%"
            height="1000px"
            allow="autoplay"
          ></iframe>
          {/* Google drive pdf taking a majority of the top row */}
        </Grid>
      </Box>
    </Container>
  );
};
export default Resume;
