import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./WorkExperience.css";
import "./PageStyling.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

/**
 * Work experience page
 * @returns {JSX.Element}
 * @constructor
 */
const WorkExperience = () => {
  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth={"xl"} sx={{ bgcolor: "#59a2d9", paddingTop: "1vh" }}>
      <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              paddingBottom: "2%",
              paddingLeft: "4%",
              paddingRight: "0.5%",
              paddingTop: "0.5%",
              height: "97.5%",
              width: "95.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href="https://www.linkedin.com/company/axle-informatics/">
                  <h2>Axle Informatics</h2>
                </a>
                <p>
                  <b>Position</b>: Data Science Research Assistant<br></br>
                </p>
                <p>
                  <b>Duration</b>: Sept 2023 - Dec 2023
                </p>
                <li>
                  Developed a python library which allows for visualization of
                  user and remote large image datasets from within both local
                  and remote JupyterLab environments.
                </li>
                <li>
                  Developed a Visual Studio Code extension allowing for quick
                  visualization of user and remote large image datasets.
                </li>
                <li>
                  Enhanced user experience and fixed bugs in a large image
                  dataset visualization application.
                </li>
                <li>Contributed to multiple GitHub repositories.</li>
                <li>
                  Participated in daily scrum meetings with project manager and
                  their team.
                </li>
                <li>
                  Presented interim and final projects to senior staff and
                  peers.
                </li>
                <li>
                  Partnered with multiple members of the data science team for
                  testing, feedback, and planning.
                </li>
              </div>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingBottom: "2%",
              paddingLeft: "4%",
              paddingRight: "0.5%",
              paddingTop: "0.5%",
              height: "97.5%",
              width: "95.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Chen's Chinese Restaurant</h2>
                <p>
                  <b>Position</b>: Front Desk Staff<br></br>
                </p>
                <p>
                  <b>Duration</b>: 2014-2023
                </p>
                <li>
                  Interacted with customer needs such as taking orders,
                  answering questions, and handing out order
                </li>
                <li>
                  Resolved customer issues when the manager is not present
                </li>
                <li>
                  Maintained a proper work environment by restocking and
                  sanitizing surfaces if necessary
                </li>
                <li>Instructed new staff working at the front desk</li>
              </div>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingBottom: "2%",
              paddingLeft: "4%",
              paddingRight: "0.5%",
              paddingTop: "0.5%",
              height: "97.5%",
              width: "95.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Zheng Garden</h2>
                <p>
                  <b>Position</b>: Front Desk Staff<br></br>
                </p>
                <p>
                  <b>Duration</b>: 2017
                </p>
                <li>Worked a seasonal position during the holidays.</li>
              </div>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingBottom: "2%",
              paddingLeft: "4%",
              paddingRight: "0.5%",
              paddingTop: "0.5%",
              height: "97.5%",
              width: "95.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Maymont Park</h2>
                <p>
                  <b>Position</b>: Cleanup Volunteer<br></br>
                </p>
                <p>
                  <b>Duration</b>: 2015-2019
                </p>
                <li>
                  Volunteered in cleanup efforts around the Maymont Japanese
                  Garden every year from 2015 to 2019 around December.
                </li>
              </div>
            </Grid>
          </Box>
          <Box
            sx={{
              paddingBottom: "2%",
              paddingLeft: "4%",
              paddingRight: "0.5%",
              paddingTop: "0.5%",
              height: "97.5%",
              width: "95.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Chesterfield County Public Schools</h2>
                <p>
                  <b>Position</b>: Science Night Volunteer<br></br>
                </p>
                <p>
                  <b>Duration</b>: 2016
                </p>
                <li>
                  Taught and entertained elementary school students about
                  chemistry.
                </li>
                <li>
                  Took a janitorial role in cleaning up accidental spills and
                  waste around experiment stations.
                </li>
              </div>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
export default WorkExperience;
