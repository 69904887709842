import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Skills.css";
import "./PageStyling.css";
import {
  Grid,
  ImageList,
  ImageListItem,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";

/**
 * Skill page
 * @param props
 *      languages: src to language icons
 *      software: src to software icons
 *      futures: src to future icons
 * @returns {JSX.Element}
 */
const Skills = (props) => {
  const languages = props.languages;
  const softwares = props.softwares;
  const futures = props.futures;
  // Hooks are used to support hovering
  const [showToolKit, setShowToolKit] = useState(false);
  const [toolcontent, settoolcontent] = useState(undefined);

  // Shows toolkit when an icon is hovered over
  const showtoolkit = (event) => {
    settoolcontent(event.target.id);
    setShowToolKit(true);
  };

  // Hides a toolkit when the is no more hovering
  const hidetoolkit = (event) => {
    setShowToolKit(false);
  };

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: "#59a2d9", paddingTop: "1vh" }}>
      <p>
        <b>Hover over or tap on the icons for alt text.</b>
      </p>
      {/* Container provides background color and width of content */}
      <Tooltip
        title={toolcontent}
        followCursor
        open={showToolKit}
        placement="top"
        arrow
      >
        {/* Tooltip hides until hover event occurs */}
        <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
          {/* Box contains all content and determines height */}
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            {/* Prop arrays are organized into a column  */}
            <h2>Languages:</h2>
            <ImageList
              variant="masonry"
              cols={3}
              gap={16}
              sx={{ width: "70%" }}
            >
              {languages.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    id={item.title}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onMouseEnter={showtoolkit}
                    onMouseOut={hidetoolkit}
                  />
                  {/* Each image is hooked up to the tookit via mouse enter and exit event */}
                </ImageListItem>
              ))}
            </ImageList>
            <h2>Software:</h2>
            <ImageList
              variant="masonry"
              cols={3}
              gap={16}
              sx={{ width: "70%" }}
            >
              {softwares.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    id={item.title}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onMouseEnter={showtoolkit}
                    onMouseOut={hidetoolkit}
                  />
                </ImageListItem>
              ))}
            </ImageList>
            <h2>Planned Skills To Learn:</h2>
            <ImageList
              variant="masonry"
              cols={2}
              gap={16}
              sx={{ width: "70%" }}
            >
              {futures.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    id={item.title}
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                    onMouseEnter={showtoolkit}
                    onMouseOut={hidetoolkit}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Box>
      </Tooltip>
    </Container>
  );
};
export default Skills;
