import torg from "../images/torg.jpg";
import "./ImageBar.css";
import { Link } from "react-router-dom";
import { useMediaQuery, useTheme } from "@mui/material";

const ImageBar = () => {
  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBarTypeQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    if (matches) {
      return (
        <div className="container" width="100%" height={"200rem"}>
          <img
            className={"torg"}
            src={torg}
            width="100%"
            height={"200rem"}
            object-fit={"cover"}
          />
          <p className="centeredalt">
            <Link to="/" className={"headLink"}>
              JC
            </Link>
          </p>
        </div>
      );
    }
    return (
      <div className="container" width="100%" height={"200rem"}>
        <img
          className={"torg"}
          src={torg}
          width="100%"
          height={"200rem"}
          object-fit={"cover"}
        />
        <p className="centeredalt">
          <Link to="/" className={"headLink"}>
            Jeff Chen
          </Link>
        </p>
      </div>
    );
  }

  return MediaBarTypeQuery();
};
export default ImageBar;
