import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Image from "mui-image";
import "./About.css";
import "./PageStyling.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

/**
 * About me page
 * @param props
 *          image: image to display
 * @returns {JSX.Element}
 */
const About = (props) => {
  const image = props.image;

  /**
   * Given the resolution of the page currently, return what the width of an image should be
   * @returns {string} width of an image in vh
   */
  function MediaImageQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("lg"));
    if (matches) {
      return "60rem";
    }
    return "40rem";
  }

  /**
   * Given the resolution of the page currently, return how wide text should be
   * @returns {string} text width in %
   */
  function MediaTextQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "40%";
    }
    return "80%";
  }

  /**
   * Given the resolution of the page currently, return the top padding % the text should have
   * @returns {string} page padding in %
   */
  function MediaTextTopPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "2%";
    }
    return "5%";
  }

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth={"xl"} sx={{ bgcolor: "#59a2d9", paddingTop: "1vh" }}>
      {/* Top level container paints the blue content panel */}
      <Box paddingBottom={MediaBoxBottomPaddingQuery} height={"100%"}>
        {/* Enclosing box which determines container height*/}
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          {/* Grid is used to create 2 equally spaced panes for pic and text */}
          <Image
            src={image}
            width={MediaImageQuery()}
            sx={{ paddingTop: "5%" }}
          />
          {/* Image on LHS, some top padding to make it look nicer*/}
          <Box
            width={MediaTextQuery()}
            sx={{
              paddingLeft: "5%",
              textAlign: "center",
              paddingTop: MediaTextTopPaddingQuery(),
              paddingRight: "0%",
            }}
          >
            {/* Body text on RHS */}
            <h1> Welcome To My Website!</h1>
            <p>
              {" "}
              Hi, My name is Jeff Chen. I graduated from Virginia Tech spring of
              2023 and majored in Computer Science and minored in Human-Computer
              Interaction. I enjoy working on new and exciting projects that
              teach me how to work with exciting technologies.
              <br />
              <br />
              Currently, I am looking for a full time entry position in Software
              Engineering, location does not matter. You can navigate the site
              by the top bar and view what I have done throughout the years. My
              contact information is on the bottom of each page, don't hesitate
              to send me a message about anything and I'll respond as soon as I
              can!
            </p>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
export default About;
