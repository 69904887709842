import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Awards.css";
import "./PageStyling.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";

/**
 * Award page
 * @param props
 *          pdf: Google Drive pdf link to embed
 * @returns {JSX.Element}
 */
const Awards = (props) => {
  const pdf = props.pdf;

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: "#59a2d9" }}>
      {/* Container colors the page and sets the width */}
      <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
        {/* Box of content determines the height */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          paddingTop={"2%"}
        >
          {/* Page elements are laid out in a column */}
          <iframe
            className={"awardPDF"}
            src={pdf}
            width="90%"
            height="720"
            allow="autoplay"
          ></iframe>
          {/* Google drive pdf taking a majority of the top row */}
          <div className={"awardtext"}>
            {/* List of awards */}
            <h2>
              Virginia Tech Dean's List (3.4 GPA with at least 12 credits)
            </h2>
            <li>Fall 2019</li>
            <li>Fall 2020</li>
            <li>Spring 2021</li>
            <li>Fall 2021</li>
            <li>Spring 2022</li>
          </div>
        </Grid>
      </Box>
    </Container>
  );
};
export default Awards;
