import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import "./Project.css";
import "./PageStyling.css";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import GitHubIcon from "@mui/icons-material/GitHub";
import WebIcon from "@mui/icons-material/Web";
import LockIcon from "@mui/icons-material/Lock";
import LinkIcon from "@mui/icons-material/Link";

/**
 * Project page
 * @returns {JSX.Element}
 */
const Project = () => {
  /**
   * Return grid orientation given screen resolution
   * @returns {string}
   */
  function DynamicDirection() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("md"));
    if (matches) {
      return "column";
    }
    return "row";
  }

  /**
   * Given the resolution of the page currently, return the bottom padding the box should have
   * @returns {string} bottom box padding in %
   * @constructor
   */
  function MediaBoxBottomPaddingQuery() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("lg"));
    if (matches) {
      return "8%";
    }
    return "24%";
  }

  return (
    <Container maxWidth="xl" sx={{ bgcolor: "#59a2d9", paddingTop: "1vh" }}>
      {/* Container for color and width */}
      <p>
        <b>
          This is not an inclusive list, feel free to visit my{" "}
          <a href="https://github.com/Jeffchen54">GitHub repository</a> to view
          the other projects I have!
        </b>
      </p>
      <Box paddingBottom={MediaBoxBottomPaddingQuery} sx={{ height: "100%" }}>
        {/* Box determining height and has some bottom padding for aesthetics */}
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* Each project is a Box organized in a column in the grid */}
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href="https://pypi.org/project/jupyterlab-polus-render/">
                  <h2>JupyterLab Polus Render</h2>
                </a>
                <p>
                  <b>Language</b>: Python/TS/JS<br></br>
                </p>
                <p>
                  JupyterLab extension consisting of a Jupyter Server Extension
                  and a python package.
                  <br />
                  Embeds Polus Render web application within JupyterLab
                  notebooks <br />
                  Enables viewing of large image datasets from within notebooks.
                  Product of Axle Informatics
                  <br />
                  internship.
                </p>
              </div>
              <a href="https://pypi.org/project/jupyterlab-polus-render/">
                <LinkIcon
                  className={"lockicon"}
                  sx={{ fontSize: "10vw" }}
                ></LinkIcon>
              </a>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Polus Render VSCode Extension</h2>
                <p>
                  <b>Language</b>: JS/TS<br></br>
                </p>
                <p>
                  VSCode extension enabling quick viewing of large image
                  datasets from within
                  <br />
                  VSCode. Embeds Polus Render web application within VSCode.
                  Product of Axle Informatics
                  <br />
                  internship.
                </p>
              </div>
              <LockIcon
                className={"lockicon"}
                sx={{ fontSize: "10vw" }}
              ></LockIcon>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href={"https://barbell-buddy.herokuapp.com/"}>
                  <h2>Barbell Buddy</h2>
                </a>
                <p>
                  <b>Language</b>: Python/HTML/CSS/Bootstrap/Django<br></br>
                </p>
                <p>
                  Workout SaaS application for people looking to pick up the
                  barbell. <br />
                  Final deliverable for the Software Engineering capstone
                  course. <br /> Implemented with Django and integrated with
                  ChatGPT.
                </p>
              </div>
              <a href={"https://barbell-buddy.herokuapp.com/"}>
                <WebIcon
                  className={"lockicon"}
                  sx={{ fontSize: "10vw" }}
                ></WebIcon>
              </a>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href={"https://virginiatech.8thwall.app/ar-campus-tour/"}>
                  <h2>VT AR Campus Tour</h2>
                </a>
                <p>
                  <b>Language</b>: 8th Wall/JS/CSS/HTML<br></br>
                </p>
                <p>
                  AR wayfarer application marking significant areas around the
                  Virginia Tech Campus. <br />
                  Built on 8th Wall, a developing WebAR platform tailored
                  towards mobile devices. <br />
                  Final deliverable for the HCI capstone course.{" "}
                </p>
              </div>
              <a href={"https://virginiatech.8thwall.app/ar-campus-tour/"}>
                <WebIcon
                  className={"lockicon"}
                  sx={{ fontSize: "10vw" }}
                ></WebIcon>
              </a>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href={"https://vtechworks.lib.vt.edu/handle/10919/114964"}>
                  <h2>Crisis Events Webpages Archiving</h2>
                </a>
                <p>
                  <b>Language</b>: Python/Flask/JS/HTML/CSS<br></br>
                </p>
                <p>
                  Application support bulk archival of webpages into WARC files
                  and replay of archived WARC files. <br />
                  Final deliverable for the Multimedia capstone course.
                </p>
              </div>
              <a href={"https://vtechworks.lib.vt.edu/handle/10919/114964"}>
                <WebIcon
                  className={"lockicon"}
                  sx={{ fontSize: "10vw" }}
                ></WebIcon>
              </a>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Resume Website</h2>
                <p>
                  <b>Language</b>: React JS/MUI<br></br>
                </p>
                <p>
                  Resume website you are on now. Website is hosted on Heroku,
                  using React JS and MUI
                  <br />
                  components, and using React routing for multiple webpage
                  support. <br />
                  Integrated with Google Analytics.Planned expansion to include
                  MongoDB databases.
                </p>
              </div>
              <LockIcon
                className={"lockicon"}
                sx={{ fontSize: "10vw" }}
              ></LockIcon>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <h2>Collaborative Data Visualization QAC Report</h2>
                <p>
                  <b>Language</b>: Python/Jupyter Notebook<br></br>
                </p>
                <p>
                  Jupyter notebook QAC report consisting of multiple parts:
                  gathering of statistics,
                  <br />
                  1st investigation, and 2nd investigation based on the result
                  of the 1st investigation’s <br />
                  conclusion. Gathering of statistics phase consisted of gather
                  reputable data.
                  <br />
                  1st investigation involved cleaning the data, analyzing the
                  data, then generating a <br />
                  conclusion on the data. The 2nd investigation investigated
                  other categories in the data <br />
                  which may be significant to the 1st investigation's findings
                </p>
              </div>
              <GitHubIcon
                className={"icon"}
                sx={{ fontSize: "10vw" }}
              ></GitHubIcon>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a href={"https://github.com/Jeffchen54/HowLongToBeat_Scraper"}>
                  <h2>How Long To Beat Scraper</h2>
                </a>
                <p>
                  <b>Language</b>: Python<br></br>
                </p>
                <p>
                  Web scraper using python's Request library. Scraped
                  https://howlongtobeat.com/
                  <br />
                  Data used in the report mentioend above.{" "}
                </p>
              </div>
              <a href={"https://github.com/Jeffchen54/HowLongToBeat_Scraper"}>
                <GitHubIcon
                  className={"icon"}
                  sx={{ fontSize: "10vw" }}
                ></GitHubIcon>
              </a>
            </Grid>
          </Box>
          <Box
            sx={{
              padding: "0.5%",
              height: "99.5%",
              width: "99.5%",
              bgcolor: "#ffffff",
              marginTop: "2%",
            }}
          >
            <Grid
              container
              direction={DynamicDirection()}
              justifyContent="space-between"
              alignItems="center"
            >
              <div className={"description"}>
                <a
                  href={
                    "https://github.com/Jeffchen54/CovidCFR/tree/master/CovidCFR"
                  }
                >
                  <h2>CovidCFR</h2>
                </a>
                <p>
                  <b>Language</b>: Java<br></br>
                </p>
                <p>
                  Collaboration project during earlier parts of the pandemic
                  examining Covid statistics
                  <br /> with GUI.
                </p>
              </div>
              <a
                href={
                  "https://github.com/Jeffchen54/CovidCFR/tree/master/CovidCFR"
                }
              >
                <GitHubIcon
                  className={"icon"}
                  sx={{ fontSize: "10vw" }}
                ></GitHubIcon>
              </a>
            </Grid>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};
export default Project;
