import "./FooterBar.css";
import Box from "@mui/material/Box";
import EmailIcon from "@mui/icons-material/Email";
import Typography from "@mui/material/Typography";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GitHubIcon from "@mui/icons-material/GitHub";
import { Grid } from "@mui/material";
const FooterBar = () => {
  return (
    <div>
      <Box
        sx={{ height: "25vh", padding: "2%", bgcolor: "#1E2761", width: "100" }}
      >
        <Grid
          paddingTop={"2vh"}
          className={"footer"}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={8}
        >
          <Grid item xs={3}>
            <EmailIcon className={"sociolcons"}></EmailIcon>
            <Typography className="footer" textAlign="center" color="white">
              <a id="personal" href="mailto:chenjeff4840@gmail.com">
                Email
              </a>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <LinkedInIcon className={"sociolcons"}></LinkedInIcon>
            <Typography className="footer" textAlign="center" color="white">
              {" "}
              <a
                id="personal"
                href={"https://www.linkedin.com/in/jeff-chenisty/"}
              >
                LinkedIn
              </a>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <GitHubIcon className={"sociolcons"}></GitHubIcon>
            <Typography className="footer" textAlign="center" color="white">
              <a id="personal" href="https://github.com/Jeffchen54">
                GitHub
              </a>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className="footer" textAlign="center" color="white">
              Version 1.3 - Last Modified 12/08/2023
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};
export default FooterBar;
